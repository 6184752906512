import React from "react"
import Layout from "../components/layout"
import pageLayout from "../pages/pages.module.css"
import background from "../Figures/Site_Header_Background.png"
import indexStyles from "./index.module.css"

export default function Contact() {
  return (
    <div>
      <div>
    <Layout></Layout>
    <div style={{backgroundImage: "url(" + background + ")", backgroundSize: "cover", minHeight: "200px"}} >
        <div className={indexStyles.header} style={{}}>
      <h1 style={{color: "white", whiteSpace:"nowrap"}}>Hey there!</h1>
      <h2 style={{color: "white"}}>You have a question, <br /> or a remark, suggestion, proposal, comment,...</h2>
        </div>
        </div>
    </div>
    <div className={pageLayout.text}>
      <h1>I'd love to talk! Email me through the form below:</h1>
      <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field">
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="bot-field" />
  <p>     <label>Your Name: </label> <br /> <input style={{width:"100%"}} type="text" name="name" /> </p>
  <p>
    <label>Your Email: <br /><input style={{width:"100%"}} type="email" name="email" /></label>
  </p>
  <p>
    <label>Message: <br /><textarea style={{width:"100%"}} name="message"></textarea></label>
  </p>
  <p>
    <button type="submit">Send</button>
  </p>
</form>
    
    </div>
    </div>
  )
}